<script>
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css";

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";

import animationData from "@/components/widgets/msoeawqm.json";
import animationData1 from "@/components/widgets/gsqxdxog.json";
import Swal from "sweetalert2";
import axios from 'axios';
import Lottie from "@/components/widgets/lottie.vue";

export default {
    setup() {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        return { Toast }
    },
    page: {
        title: "Contacts",
        meta: [{
            name: "description",
            content: appConfig.description
        }],
    },
    components: {
        Layout,
        PageHeader,
        lottie: Lottie,
        // Multiselect
    },
    data() {
        return {
            title: "Divisi",
            items: [{
                text: "Xmart",
                href: "/",
            },
            {
                text: "Divisi",
                active: true,
            },
            ],
            timeConfig: {
                enableTime: false,
                dateFormat: "d M, Y",
            },
            date2: null,
            defaultOptions: {
                animationData: animationData
            },
            defaultOptions1: {
                animationData: animationData1
            },
            searchQuery: null,
            page: 1,
            timeout: null,
            perPage: 10,
            pages: [],
            formAddCustomer: {
                name: '',
                defaultAssign: false,
            },
            value: null,
            tagvalue: null,
            contactsList: [],
            isEdit: false,
            isUnlink: false,
            fromValid: false,
            allFromValid: false,
            agentList: [],
            agentSelected: [],
            showWarning: false,
            profile: require("@/assets/images/users/user-dummy-img.jpg"),
        };
    },
    computed: {
        displayedPosts() {
            return this.paginate(this.contactsList);
        },
        resultQuery() {
            if (this.searchQuery) {
                const search = this.searchQuery.toLowerCase();
                return this.displayedPosts.filter((data) => {
                    return (
                        data.name.toLowerCase().includes(search)
                    );
                });
            } else {
                return this.displayedPosts;
            }
        },
    },
    watch: {
        contactsList() {
            this.setPages();
        },
    },
    filters: {
        trimWords(value) {
            return value.split(" ").splice(0, 20).join(" ") + "...";
        },
    },
    methods: {
        setPages() {
            let numberOfPages = Math.ceil(this.contactsList.length / this.perPage);
            this.pages = [];
            for (let index = 1; index <= numberOfPages; index++) {
                this.pages.push(index);
            }
        },
        paginate(contactsList) {
            let page = this.page;
            let perPage = this.perPage;
            let from = page * perPage - perPage;
            let to = page * perPage;
            return contactsList.slice(from, to);
        },
        editdata(data) {
            this.isEdit = true;
            this.formAddCustomer.name = data.name
            this.formAddCustomer.defaultAssign = data.defaultAssign

            if(data.dafaultAssign){
                document.querySelector("#SwitchCheck3-edit").checked = true;
            }
            document.getElementById("id").value = data.id
            document.getElementById('exampleModalLabel').innerHTML = "Update Divisi";
            document.getElementById('edit-btn').style.display = 'block';
            document.getElementById('add-btn').style.display = 'none'
        },
        async updateorder() {

            var ClientID = process.env.VUE_APP_CLIENT_ID;
            var AccessToken = localStorage.getItem("jwt")

            var id = document.getElementById("id").value
            var data = new FormData();
            data.append("Title", this.formAddCustomer.name);
            data.append("DefaultAssign", this.formAddCustomer.defaultAssign);

            const saveEdited = await axios.post(process.env.VUE_APP_URL_API + "divisi/update/"+id+"?ClientID=" + ClientID + "&AccessToken=" + AccessToken, data)
                .then((response) => {
                    return response
                }).catch((er) => {
                    return er
                });

            var responseStatusCode = saveEdited.data.Meta.Code
            var responseStatusMsg = saveEdited.data.Meta.Message

            if (responseStatusCode != 200) {
                this.Toast.fire({
                    icon: 'error',
                    title: 'Internal Server Error\n'+responseStatusMsg
                })
            } else {
                this.Toast.fire({
                    icon: 'success',
                    title: responseStatusMsg
                })
            }

            const dataResult = saveEdited.data.Data
            let divisiListID = this.contactsList.findIndex(el => el.id == dataResult.ID);
            this.contactsList.map((item, idx) => {
                if(idx != divisiListID){
                    this.contactsList[idx].defaultAssign = false;
                }
            })
            this.contactsList[divisiListID].name = dataResult.Title
            this.contactsList[divisiListID].defaultAssign = dataResult.DefaultAssign

            document.getElementById('edtclosemodal').click();
            document.getElementById("addform").reset();
            this.refresh();
        },
        async addorder() {
            this.isEdit = false;

            var params = "?ClientID="+process.env.VUE_APP_CLIENT_ID+"&AccessToken="+localStorage.getItem("jwt");
            var formData = new FormData();
            formData.append("Title", this.formAddCustomer.name);
            const save = await axios.post(process.env.VUE_APP_URL_API+"divisi/add"+params, formData)

            var responseStatusCode = save.data.Meta.Code
            var responseStatusMsg = save.data.Meta.Message
            var responseData = save.data.Data

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            if (responseStatusCode != 200) {
                Toast.fire({
                    icon: 'error',
                    title: responseStatusMsg
                })
            } else {
                Toast.fire({
                    icon: 'success',
                    title: responseStatusMsg
                })

                this.contactsList.push({
                    id: responseData.ID,
                    contactId: "#VZ00" + this.contactsList.length + 1,
                    name: responseData.Title,
                    agent: responseData.Agent
                })

                document.getElementById('edtclosemodal').click();
                document.getElementById("addform").reset();
                this.refresh();
            }
        },
        deletedata(event) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Yes, delete it!",
            }).then( async (result) => {
                if (result.value) {
                    const listDeleted = this.contactsList[this.contactsList.indexOf(event)];
                    var url = process.env.VUE_APP_URL_API+"divisi/delete/"+listDeleted.id+
                    "?ClientID="+process.env.VUE_APP_CLIENT_ID+"&AccessToken="+localStorage.getItem("jwt");

                    const deleteSave = await axios.get(url);

                    if(deleteSave.data.Meta.Code == 200){
                        this.refresh();
                        Swal.fire("Deleted!", "Your file has been deleted.", "success");
                    }
                }
            });
        },
        addnew() {
            this.isEdit = false;
            this.formAddCustomer.name = "";
            this.formAddCustomer.email = "";
            this.formAddCustomer.phone = "";
            this.formAddCustomer.username = "";
            this.formAddCustomer.role = "";
            this.formAddCustomer.password = "";
            this.formAddCustomer.verfi_pass = "";

            document.getElementById("addform").reset();
            document.getElementById('exampleModalLabel').innerHTML = "Add Agent";
            document.getElementById('add-btn').style.display = 'block';
            document.getElementById('edit-btn').style.display = 'none';
        },
        validatePass(){
            clearTimeout(this.timeout);
            var self = this;
            this.timeout = setTimeout(() => {
                if(self.formAddCustomer.verfi_pass === self.formAddCustomer.password){
                    self.fromValid = true
                } else {
                    self.fromValid = false
                }
            }, 500);
        },
        validateFrom(){
            this.allFromValid = true;
            this.fromValid = true;
        },
        addagent(data){

            var url = `${process.env.VUE_APP_URL_API}divisi/getAgent`;
            axios.get(url, { params: {
                ClientID: process.env.VUE_APP_CLIENT_ID,
                AccessToken: localStorage.getItem("jwt"),
                Filter: "out"
            }}).then(res => {
                if(res.data.Meta.Code === 200){
                    this.agentList = res.data.Data;
                }
            }).catch(err => {
                this.Toast.fire({
                    icon: 'error',
                    text: 'Internal Server Error'
                })
                console.log(err);
            })

            document.getElementById("DevisiID").value = data.id;
            document.getElementById('exampleModalLabelAdd').innerHTML = "Set Agent";
            document.getElementById('edit-btnAdd').style.display = 'block';
        },
        setAgent(){
            var devisiID = document.getElementById("DevisiID").value;
            var AgentID = JSON.stringify(this.agentSelected);

            var formData = new FormData();
            formData.append("AgentID", AgentID);
            formData.append("DivisiID", devisiID);

            var url = `${process.env.VUE_APP_URL_API}divisi/setAgent`
            axios.post(url, formData, {
                params: {
                    ClientID: process.env.VUE_APP_CLIENT_ID,
                    AccessToken: localStorage.getItem("jwt")
                }
            }).then(res => {
                if(res.data.Meta.Code == 200){
                    let message = '';
                    res.data.Data.forEach(item => {
                        message += item+" \n"
                    })
                    this.Toast.fire({
                        icon: 'success',
                        text: message
                    })
                    this.refresh();
                } else {
                    this.Toast.fire({
                        icon: 'warning',
                        text: res.data.Meta.Message
                    })
                }
            }).catch(err => {
                this.Toast.fire({
                    icon: 'error',
                    text: 'Internal Server Error'
                })
                console.log(err);
            })

            document.querySelector("#edtclosemodalDivisi").click();
        },
        refresh(){
            this.contactsList = [];
            this.agentSelected = [];
            var baseUrl = process.env.VUE_APP_URL_API;
            var url = baseUrl + "divisi/get?ClientID=" + process.env.VUE_APP_CLIENT_ID + "&AccessToken=" + localStorage.getItem("jwt")

            axios.get(url).then((data) => {
                const res = data.data;
                if (res.Meta.Code == 200) {
                    res.Data.forEach((item, index) => {
                        this.contactsList.push({
                            id: item.ID,
                            contactId: "#VZ00" + index,
                            name: item.Title,
                            agent: item.Agent,
                            defaultAssign: item.DefaultAssign == 0 ? false : true,
                        })
                    })

                    const defAssign = this.contactsList.find(item => item.defaultAssign == true);
                    if(defAssign == undefined){
                        this.showWarning = true;
                    } else {
                        this.showWarning = false;
                    }

                    this.setPages();
                }
            }).catch((er) => {
                this.Toast.fire({
                    icon: 'error',
                    text: 'Internal Server Error'
                })
                console.log(er)
            });
        },
        unlinkagent(data){
            this.isUnlink = true;
            var url = `${process.env.VUE_APP_URL_API}divisi/getAgent`
            axios.get(url, {
                params: {
                    ClientID: process.env.VUE_APP_CLIENT_ID,
                    AccessToken: localStorage.getItem("jwt"),
                    Filter: "in"
                }
            }).then(res => {
                if(res.data.Meta.Code == 200){
                    var agentInDivisi = res.data.Data.filter(el => el.Divisi.ID === data.id)
                    this.agentList = agentInDivisi;
                }
            }).catch(err => {
                this.Toast.fire({
                    icon: "error",
                    text: "Internal Server Error"
                })
                console.log(err);
            })
        },
        removeAgent(){
            var url = `${process.env.VUE_APP_URL_API}divisi/unlinkAgent`
            var agentID = JSON.stringify(this.agentSelected);
            var formData = new FormData();
            formData.append("AgentID", agentID);
            axios.post(url, formData, {
                params: {
                    ClientID: process.env.VUE_APP_CLIENT_ID,
                    AccessToken: localStorage.getItem("jwt")
                }
            }).then(res => {
                if(res.data.Meta.Code == 200){
                    this.refresh();
                }
            }).catch(err => {
                this.Toast.fire({
                    icon: 'error',
                    text: 'Internal Server Error'
                })
                console.log(err);
            })

            document.querySelector("#edtclosemodalDivisi").click();
        }
    },
    beforeMount() {
        this.refresh();        
    },
    mounted() {
        var checkAll = document.getElementById("checkAll");
        if (checkAll) {
            checkAll.onclick = function () {
                var checkboxes = document.querySelectorAll(
                    '.form-check-all input[type="checkbox"]'
                );
                if (checkAll.checked == true) {
                    checkboxes.forEach(function (checkbox) {
                        checkbox.checked = true;
                        checkbox.closest("tr").classList.add("table-active");
                    });
                } else {
                    checkboxes.forEach(function (checkbox) {
                        checkbox.checked = false;
                        checkbox.closest("tr").classList.remove("table-active");
                    });
                }
            };
        }
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <!-- Warning Alert -->
        <div v-if="showWarning" class="alert alert-warning alert-dismissible alert-additional shadow fade show mb-4" role="alert">
            <div class="alert-body">
                <div class="d-flex">
                    <div class="flex-shrink-0 me-3">
                        <i class="ri-alert-line fs-16 align-middle"></i>
                    </div>
                    <div class="flex-grow-1">
                        <h5 class="alert-heading">WARNING !!! Tidak ada divisi yang dijadikan default assign</h5>
                        <p class="mb-0">Jika tidak ada yang di jadikan default assign maka ketika ada leads customer masuk system akan melakukan random divisi. </p>
                    </div>
                </div>
            </div>
            <div class="alert-content">
                <p class="mb-0">Warning ini dapat mempengaruhi kinerja system dalam melakukan assign customer sesuai yang di butuhkan</p>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center flex-wrap gap-2">
                            <div class="flex-grow-1">
                                <button class="btn btn-info add-btn me-1" data-bs-toggle="modal" href="#showmodal"
                                    @click="addnew">
                                    <i class="ri-add-fill me-1 align-bottom"></i> Add Divisi
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
            <div class="col-xxl-9">
                <div class="card" id="contactList">
                    <div class="card-header">
                        <div class="row g-3">
                            <div class="col-md-4">
                                <div class="search-box">
                                    <input v-model="searchQuery" type="text" class="form-control search"
                                        placeholder="Search for contact..." />
                                    <i class="ri-search-line search-icon"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div>
                            <div class="table-responsive table-card mb-3">
                                <table class="table align-middle table-nowrap mb-0" id="customerTable">
                                    <thead class="table-light">
                                        <tr>
                                            <th scope="col" style="width: 50px">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="checkAll"
                                                        value="option" />
                                                </div>
                                            </th>
                                            <th class="sort" data-sort="name" scope="col">Title</th>
                                            <th class="sort" data-sort="dafault_assign" scope="col">Default Assign</th>
                                            <th class="sort" data-sort="phone" scope="col">Agent</th>
                                            <th class="text-end" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="resultQuery.length > 0" class="list form-check-all">
                                        <tr v-for="(data, index) of resultQuery" :key="index">
                                            <th scope="row">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="chk_child"
                                                        value="option1" />
                                                </div>
                                            </th>
                                            <td class="id" style="display: none">
                                                <a href="javascript:void(0);" class="fw-medium link-primary">{{ data.id
                                                    }}</a>
                                            </td>
                                            <td class="">
                                                <div class="d-flex align-items-center">
                                                    <div class="flex-grow-1 ms-2 name">{{ data.name }}</div>
                                                </div>
                                            </td>
                                            <td class="dafault_assign">
                                                <span v-if="data.defaultAssign" class="badge border border-success text-success">Yes</span>
                                                <span v-else class="badge border border-danger text-danger">No</span>
                                            </td>
                                            <td class="phone">
                                                <div class="avatar-group">
                                                    <div class="avatar-group-item shadow" v-for="(item, index) of data.agent" :key="index">
                                                        <a href="javascript: void(0);" class="d-inline-block" data-bs-toggle="tooltip"
                                                            data-bs-placement="top" :title="item.Name" data-bs-original-title="Stine Nielsen">
                                                            <img v-if="item.Avatar" :src="`${item.Avatar.Original}`" alt="" class="rounded-circle avatar-xxs">
                                                            <img v-else :src="profile" alt="" class="rounded-circle avatar-xxs">
                                                        </a>
                                                    </div>

                                                    <div class="avatar-group-item shadow">
                                                        <a href="javascript: void(0);">
                                                            <div class="avatar-xxs">
                                                                <span :class="`avatar-title rounded-circle bg-info text-white`">
                                                                    {{ data.agent.length }}
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-end">
                                                <button @click="addagent(data)" data-bs-toggle="modal" href="#addmodal" class="btn btn-outline-info btn-sm me-2">Add Agent</button>
                                                <button @click="unlinkagent(data)" data-bs-toggle="modal" href="#addmodal" class="btn btn-outline-danger btn-sm me-2">Unlink Agent</button>
                                                <button @click="editdata(data)" data-bs-toggle="modal" href="#showmodal" class="btn btn-warning btn-sm me-2">Edit</button>
                                                <button @click="deletedata(data)" class="btn btn-danger btn-sm">Hapus</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="5">
                                                <div class="noresult">
                                                    <div class="text-center">
                                                        <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a"
                                                            :options="defaultOptions" :height="75" :width="75" />
                                                        <h5 class="mt-2">Sorry! No Result Found</h5>
                                                        <p class="text-muted mb-0">
                                                            We've searched more than 150+ Orders We did not find any
                                                            orders for you search.
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-flex justify-content-end mt-3">
                                <div class="pagination-wrap hstack gap-2">
                                    <a class="page-item pagination-prev disabled" href="#" v-if="page != 1"
                                        @click="page--">
                                        Previous
                                    </a>
                                    <ul class="pagination listjs-pagination mb-0">
                                        <li :class="{
                                            active: pageNumber == page,
                                            disabled: pageNumber == '...',
                                        }" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index"
                                            @click="page = pageNumber">
                                            <a class="page" href="#">{{ pageNumber }}</a>
                                        </li>
                                    </ul>
                                    <a class="page-item pagination-next" href="#" @click="page++"
                                        v-if="page < pages.length">
                                        Next
                                    </a>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <!--end card-->
            </div>
            <!--end col-->

        </div>
        <!--end row-->

        <div class="modal fade" ref="modalsForm" id="showmodal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content border-0">
                    <div class="modal-header bg-soft-info p-3">
                        <h5 class="modal-title" id="exampleModalLabel"></h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            id="close-modal"></button>
                    </div>
                    <form action="" id="addform">
                        <div class="modal-body">
                            <input type="hidden" id="id" />
                            <div class="row g-3">
                                <div class="col-lg-12">
                                    <div>
                                        <label for="name-field" class="form-label">Title</label>
                                        <input v-model="this.formAddCustomer.name" type="text" id="name"
                                            class="form-control" @keyup="validateFrom" placeholder="Enter Name" required />
                                        <div class="form-check form-switch form-switch-success mt-3">
                                            <input v-model="this.formAddCustomer.defaultAssign" class="form-check-input" type="checkbox" role="switch" id="SwitchCheck3-edit">
                                            <label class="form-check-label" for="SwitchCheck3">Set to default assign divisi</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="hstack gap-2 justify-content-end">
                                <button type="button" class="btn btn-light" data-bs-dismiss="modal"
                                    id="edtclosemodal">Close</button>
                                <button type="button" class="btn btn-success" :class="{disabled: (!this.fromValid || !this.allFromValid)}" id="add-btn" @click="addorder">
                                    Add Divisi
                                </button>
                                <button type="button" class="btn btn-warning" id="edit-btn"
                                    @click="updateorder()">Update</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" ref="modalsForm" id="addmodal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content border-0">
                    <div class="modal-header bg-soft-info p-3">
                        <h5 class="modal-title" id="exampleModalLabelAdd"></h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            id="close-modal"></button>
                    </div>
                    <form action="" id="addform">
                        <div class="modal-body">
                            <input type="hidden" id="DevisiID" />
                            <div class="row g-3">
                                <div class="col-lg-12">
                                    <table>
                                        <tr v-for="agent in agentList" :key="agent.ID">
                                            <td>
                                                <!-- Custom Outline Checkboxes -->
                                                <div class="form-check form-check-outline form-check-primary mb-3">
                                                    <input class="form-check-input" type="checkbox" id="formCheck13" :value="agent.ID" v-model="agentSelected" >
                                                    <label class="form-check-label" for="formCheck13">
                                                        {{ agent.Name }}
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <p v-if="!agentList.length" class="text-center">All agent already have divisi</p>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="hstack gap-2 justify-content-end">
                                <button @click="this.agentSelected = []" type="button" class="btn btn-light" data-bs-dismiss="modal"
                                    id="edtclosemodalDivisi">Close</button>
                                <button @click="setAgent" v-if="!isUnlink" type="button" class="btn btn-success" id="edit-btnAdd">Save</button>
                                <button @click="removeAgent" v-if="isUnlink" type="button" class="btn btn-success" id="edit-btnAdd">Unlink</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Layout>
</template>